<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9092 10.0906C14.9636 11.145 14.9636 12.8546 13.9092 13.909C12.8548 14.9634 11.1453 14.9634 10.0908 13.909C9.03641 12.8546 9.03641 11.145 10.0908 10.0906C11.1453 9.0362 12.8548 9.0362 13.9092 10.0906" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.24999 12C5.24999 12.297 5.27699 12.594 5.31299 12.882L3.72499 14.124C3.37299 14.4 3.27699 14.893 3.50099 15.28L4.91299 17.723C5.13599 18.11 5.60999 18.273 6.02499 18.107L7.44699 17.536C7.72799 17.423 8.04099 17.468 8.29299 17.635C8.51299 17.781 8.74099 17.915 8.97699 18.035C9.24699 18.172 9.44299 18.417 9.48599 18.717L9.70299 20.23C9.76599 20.672 10.145 21 10.591 21H13.408C13.854 21 14.233 20.672 14.296 20.23L14.513 18.718C14.556 18.418 14.754 18.171 15.025 18.035C15.26 17.917 15.487 17.784 15.706 17.639C15.96 17.471 16.274 17.423 16.556 17.537L17.975 18.107C18.389 18.273 18.863 18.11 19.087 17.723L20.499 15.28C20.723 14.893 20.627 14.399 20.275 14.124L18.687 12.882C18.723 12.594 18.75 12.297 18.75 12C18.75 11.703 18.723 11.406 18.687 11.118L20.275 9.876C20.627 9.6 20.723 9.107 20.499 8.72L19.087 6.277C18.864 5.89 18.39 5.727 17.975 5.893L16.556 6.463C16.274 6.576 15.96 6.529 15.706 6.361C15.487 6.216 15.26 6.083 15.025 5.965C14.754 5.829 14.556 5.582 14.513 5.282L14.297 3.77C14.234 3.328 13.855 3 13.409 3H10.592C10.146 3 9.76699 3.328 9.70399 3.77L9.48599 5.284C9.44299 5.583 9.24599 5.829 8.97699 5.966C8.74099 6.086 8.51299 6.221 8.29299 6.366C8.03999 6.532 7.72699 6.577 7.44599 6.464L6.02499 5.893C5.60999 5.727 5.13599 5.89 4.91299 6.277L3.50099 8.72C3.27699 9.107 3.37299 9.601 3.72499 9.876L5.31299 11.118C5.27699 11.406 5.24999 11.703 5.24999 12V12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
  export default {
      
  }
</script>

<style lang="scss" scoped>

</style>