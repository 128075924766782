<template>
  <div class="min-h-screen">
    <div class="h-12 w-full border-b"></div>
    <div class="pt-40 flex flex-wrap">
      <div class="w-1/5"></div>
      <div class="w-2/4 px-4">
        <p class="text-black font-bold head-title leading-none mb-7">Discovery Jobs</p>
        <p class="text-2xl text-black leading-8 mb-16">
          These are the Discovery Jobs that you did in the past. 
          You can use this page to see the progress of your current 
          jobs or see the results of the ones you did in the past.
        </p>
      </div>
    </div>
    <div class="container mx-auto w-4/5 flex flex-wrap">
      <Title 
        title="Jobs currently processing" 
        headClasses="pl-3" 
        class="w-full"
      >
        <template v-slot:icon>
          <settings class="mr-3"/>
        </template>
      </Title>
      <div class="flex flex-wrap w-full mb-16 border-b border-dashed border-grey-darker-200">
        <div class="w-3/5 relative border-r border-dashed border-grey-darker-200">
          <div class="absolute right-0 w-10 h-full right-shadow"></div>
          <TitleSmallMono  text="JOB NAME" class="pl-4"/>
          <p class="base-font-gray-200 text-xl pb-4 pl-4 border-b border-dashed border-grey-darker-200">
            bf7bca93-5285-4b77-b9e0-2118c919ff5e
          </p>
          <TitleSmallMono  text="DESCRIPTION" class="pl-4"/>
          <p class="base-font-gray-200 text-xl pb-4 pl-4">
            Seed list is based on videos found in Telegram channel of Proud Boys
          </p>
          <div class="flex flex-wrap w-full pb-4">
            <div class="w-1/2">
              <TitleSmallMono  text="SEED VIDEO ID’S" class="pl-4"/>
              <ul class="pl-4">
                <li 
                  v-for="(id, index) in ids"
                  :key="`${id}${index}`"
                  class="inline-block mr-3 mb-2"
                >
                  <span class="text-xs base-font-gray-light-800 px-3 py-1 rounded-lg base-bg">{{ id }}</span>
                </li>
              </ul>
            </div>
            <div class="w-1/2">
              <TitleSmallMono  text="PROCESSING DATE" />
              <p class="base-font-gray-200 text-xl pb-4">
                23-02-2021
              </p>
            </div>
          </div>
        </div>
        <div class="w-2/5 relative">
          <div class="absolute left-14 h-full w-px border-l border-dashed border-grey-darker-200"></div>
          <div class="bg-white relative">
            <TitleSmallMono  text="CURRENT STAGE" class="mt-10 pl-8 mb-2"/>
          </div>
          <ul class="pl-20">
            <li class="mb-6 relative">
              <span class="absolute -left-7 top-1/2 -mt-2 py-1 bg-white">
                <span class="w-2 h-2 rounded-full bg-black block"></span>
              </span>
              <span class="text-xl font-grey-darker-500">
                1/3 Indexing Comments
              </span>
            </li>
            <li class="mb-6 relative">
              <span class="absolute -left-7 top-1/2 -mt-2 py-1 bg-white">
                <span class="w-2 h-2 rounded-full purple-bg block"></span>
              </span>
              <span class="text-xl purple-text">
                2/3 Indexing Profiles
              </span>
            </li>
            <li class="mb-6 relative">
              <span class="absolute -left-7 top-1/2 -mt-2 py-1 bg-white">
                <span class="w-2 h-2 rounded-full base-bg-darker block"></span>
              </span>
              <span class="text-xl base-font-gray-light-700">
                3/3 Analysis
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Title 
        title="Jobs finished" 
        headClasses="pl-3" 
        class="w-full"
        :search="true" 
        placeholder="Type here your searchterm"
        @search="filterJobs"
      >
        <template v-slot:icon>
          <checkMarkDone class="mr-3"/>
        </template>
      </Title>
      <div 
        v-for="(item, index) in finishedJobs"
        :key="item"
        class="flex flex-wrap w-full border-b border-dashed border-grey-darker-200"
        :class="{
          'mb-24': (index + 1) == finishedJobs.length
        }"
      >      
        <div class="w-3/5 pb-4">
          <TitleSmallMono  text="JOB NAME" class="pl-4"/>
          <p class="base-font-gray-200 text-xl pl-4">
            bf7bca93-5285-4b77-b9e0-2118c919ff5e
          </p>
        </div>
        <div class="flex flex-wrap justify-between items-center w-2/5 pb-4">
          <div>
            <TitleSmallMono  text="PROCESSING DATE" />
            <p class="base-font-gray-200 text-xl">
              23-02-2021
            </p>
          </div>
          <button 
            class="font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none purple-bg purple-light whitespace-nowrap rounded-2xl px-5 py-2 mr-4 mt-4"
          >
            View Analysis
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@/components/icons/settings'
import checkMarkDone from '@/components/icons/checkMarkDone'
import Title from '@/components/Title'
import TitleSmallMono from '@/components/TitleSmallMono'

export default {
  name: 'DiscoveryJobs',
  components:{
    settings,
    checkMarkDone,
    Title,
    TitleSmallMono
  },
  data(){
    return{
      ids: ['i6CfYoMpISA', 'i6CfYoMpISA', 'i6CfYoMpISA', 'i6CfYoMpISA'],
      finishedJobs: [1, 2]
    }
  },
  methods: {
    filterJobs(arg){
      console.log(arg)
    }
  },
};
</script>

<style lang="scss" scoped>
.right-shadow{
  background: linear-gradient(270deg, rgba(196, 196, 196, 0.1) 0%, rgba(196, 196, 196, 0) 88.46%);
}
</style>