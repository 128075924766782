<template>
  <p class="text-sm base-font-gray-light-400 py-4">{{ text }}</p>
</template>

<script>
  export default {
    props: [ 'text' ]
  }
</script>

<style lang="scss" scoped>

</style>